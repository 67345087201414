import logo from "./logo.svg";
import "./App.css";
import Typing from "react-typing-animation";

function App() {
  return (
    <div className="App">
      <video
        id="myVideo"
        playsinline="playsinline"
        autoplay="autoplay"
        muted="muted"
        loop="loop"
      >
        <source
          src="https://www.florianvidal.com/assets/871272572.mp4"
          type="video/mp4"
        />
      </video>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ width: "60%", textAlign: "center" }}>
          <Typing>
            <span>
              // A Global Digital Transformation agency, a collective of
              developers, designers and marketing experts... Website in
              construction //
            </span>
          </Typing>
        </p>
        <a
          className="App-link"
          href="mailto:contact@florianvidal.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontWeight: "bold" }}
        >
          Contact
        </a>
      </header>
    </div>
  );
}

export default App;
